<template>
  <vs-row class="full-height" :style="{ margin: '0px' }">
    <vs-row vs-justify="center">
      <vs-col
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
        class="pt-4"
      >
        <div>
          <div v-if="loading">
            <h3>Loading Form ...</h3>
            <!-- <vs-progress indeterminate color="dark">primary</vs-progress> -->
          </div>
          <div v-else>
            <cassielFrom
              :shortUrl="shortUrl"
              :widgetName="widgetName"
              :entityName="entityName"
              :formConfiguration="formConfiguration"
              :scheduleEnabled="scheduleEnabled"
              :cassielid="cassielid"
              @submit="submit"
              @validate="validate"
            />
          </div>
        </div>
      </vs-col>
    </vs-row>
  </vs-row>
</template>

<script>
import "@/assets/magicsuggest/css/magicsuggest.css";
import cassielFrom from "@/views/edit/Component/cassielForm";

export default {
  name: "edit",
  mixins: [],
  props: {
    widgetName: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      default: "prop2",
    },
  },
  components: {
    cassielFrom,
  },
  watch: {
    $route(to, from) {
      if ((from.path === to.path) && (from.query.cassielId !== to.query.cassielId)) {
        location.reload();
      }
    },
  },
  data: () => ({
    loading: true,
    formConfiguration: [],
    scheduleEnabled: false,
    shortUrl: "",
    cassielid: "",
  }),

  created() {
    this.init();
  },
  mounted() {
    this.$vs.loading({
      type: "corners",
    });
  },
  methods: {
    /**
     * get the form configuration and structure
     */
    init: function () {
      this.loading = true;

      const baseFormConfigurationUrl = this.$apiList.baseFormConfigurationAPI;
      let formConfigurationUrl =
        baseFormConfigurationUrl +
        "/" +
        this.widgetName +
        "/" +
        this.entityName;

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      let bodyFormData = new FormData();

      Object.entries(this.$route.query).forEach(([key, value]) => {
        bodyFormData.append(key, value);
        if (key === "cassielId") {
          this.cassielid = value;
        }
      });

      this.$http({
        url: formConfigurationUrl,
        method: "POST",
        data: bodyFormData,
        headers: headers,
      }).then((response) => {
        this.shortUrl = response.data.shortUrl || "";
        this.formConfiguration = response.data.configuration;
        this.scheduleEnabled = response.data.scheduleEnabled;
        this.loading = false;
        this.$vs.loading.close();
      });
    },

    validate: function (cassielAction, tabs) {
      let dataObject = null;
      switch (cassielAction) {
        case "delete":
          this.submit(cassielAction);
          break;
        default:
          dataObject = this.validateMyFormLocally(tabs);
          if (dataObject) {
            this.submit(cassielAction, dataObject);
          }
          break;
      }
    },

    validateMyFormLocally: function (tabs) {
      function checkIfInputIsValid(oneInput) {
        //if input is required and no value or empty value
        if (oneInput.required && oneInput.required.toString() === "true") {
          if (
            oneInput.value === undefined ||
            oneInput.value === null ||
            (typeof oneInput.value === "string" && oneInput.value.trim() === "")
          ) {
            return false;
          }
        }
        return true;
      }

      let errors = [];
      let dataObject = {};
      if (tabs || Array.isArray(this.formConfiguration.data) === false) {
        this.formConfiguration.forEach((oneTab) => {
          if (oneTab.data && Array.isArray(oneTab.data)) {
            oneTab.data.forEach((oneInput) => {
              let status = checkIfInputIsValid(oneInput);
              if (status) {
                dataObject[oneInput.name] = oneInput.value;
              } else {
                errors.push({
                  type: "danger",
                  title: "Form Input(s) Validation",
                  text: `Missing or Invalid value for required field "${oneInput.name}"`,
                });
              }
            });
          } else {
            let status = checkIfInputIsValid(oneTab);
            if (status) {
              dataObject[oneTab.name] = oneTab.value;
            } else {
              errors.push({
                type: "danger",
                title: "Form Input(s) Validation",
                text: `Missing or Invalid value for required field "${oneTab.name}"`,
              });
            }
          }
        });
      } else {
        this.formConfiguration.data.forEach((oneInput) => {
          let status = checkIfInputIsValid(oneInput);
          if (!status) {
            dataObject[oneInput.name] = oneInput.value;
          } else {
            errors.push({
              type: "danger",
              title: "Form Input(s) Validation",
              text: `Missing or Invalid value for required field "${oneInput.name}"`,
            });
          }
        });
      }

      if (errors.length > 0) {
        this.$store.dispatch("globalMessages/set", errors);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }

      //true if no errors, false if there are errors.
      return errors.length === 0 ? dataObject : null;
    },

    displayAPIErrors: function (response, redirect) {
      const _self = this;
      let swal = [
        {
          template: {},
        },
      ];

      let message = JSON.parse(response.data.message);
      let messageString = "";
      for (const key in message) {
        messageString += `${message[key]} \n`;
      }

      swal["templateObj"] = {
        title: "Oops...",
        icon: "error",
        text: messageString,
        confirmButtonText: "Ok",
        showCancelButton: false,
        confirmButtonColor: "#008080",
      };

      //Run swal plugin here..
      this.$vs.loading.close();
      let thisSwal = this.$swal(swal["templateObj"]);
      if (redirect) {
        thisSwal.then(() => {
          _self.$router.push({
            name: "List Page",
            params: {
              widgetName: _self.widgetName,
              entityName: _self.entityName,
            },
          });
        });
      }
    },

    validateMyFormRemotely: function (formData, headers, cb) {
      let formSubmitUrl =
        this.$apiList.baseFormValidationApi +
        "/" +
        this.widgetName +
        "/" +
        this.entityName;

      this.$http({
        url: formSubmitUrl,
        method: "POST",
        data: formData,
        headers: headers,
      })
        .then((response) => {
          let redirect = false;
          if (response.data.error === "true") {
            this.displayAPIErrors(response, redirect);
          } else if (response.data.error === "false") {
            return cb();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.error(error);
          this.$swal({
            title: "Oops...",
            text: "Something went wrong!",
            confirmButtonText: "Ok",
            showCancelButton: false,
            confirmButtonColor: "#008080",
            customClass: {
              title: "error",
            },
          });
        });
    },

    submit: function (cassielAction, dataObject) {
      const _self = this;

      let formData = new FormData(document.getElementById("adminForm"));
      formData.set("cassielAction", cassielAction);

      for (let i in dataObject) {
        if (i.includes("[]") && Array.isArray(dataObject[i])) {
          formData.delete(i);
          dataObject[i].forEach((oneI) => {
            formData.append(i, JSON.stringify(oneI));
          });
        }
      }

      if (this.cassielid) {
        formData.delete("cassielId");
        formData.append("cassielId", this.cassielid);
      }

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }

      _self.$vs.loading({
        type: "corners",
      });
      this.validateMyFormRemotely(formData, headers, () => {
        let formSubmitUrl =
          this.$apiList.baseFormSubmitAPI +
          "/" +
          this.widgetName +
          "/" +
          this.entityName;

        this.$http({
          url: formSubmitUrl,
          method: "POST",
          data: formData,
          headers: headers,
        })
          .then((response) => {
            _self.$vs.loading.close();
            let redirect = false;
            let swal = [
              {
                template: {},
              },
            ];

            if (response.data.error === "false") {
              this.cassielid = response.data.cassielId;
              this.shortUrl = response.data.shortUrl;
              if (cassielAction === "save") {
                swal["templateObj"] = {
                  title: "Saved!",
                  text: "This record has been saved",
                  confirmButtonText: "close",
                  showCancelButton: false,
                  confirmButtonColor: "#008080",
                };
              }
              if (cassielAction === "save-close") {
                redirect = true;
                swal["templateObj"] = {
                  title: "Saved!",
                  text: "This record has been saved",
                  confirmButtonText: "close",
                  showCancelButton: false,
                  confirmButtonColor: "#008080",
                };
              }
              if (cassielAction === "save-publish") {
                redirect = true;
                swal["templateObj"] = {
                  title: "Published!",
                  text: "This record has been published",
                  confirmButtonText: "close",
                  showCancelButton: false,
                  confirmButtonColor: "#008080",
                };
              }
              if (cassielAction === "schedule") {
                redirect = true;
                swal["templateObj"] = {
                  title: "Scheduled!",
                  text: "This record has been scheduled",
                  confirmButtonText: "close",
                  showCancelButton: false,
                  confirmButtonColor: "#008080",
                };
              }
              if (cassielAction === "unpublish") {
                redirect = true;
                swal["templateObj"] = {
                  title: "Unpublished!",
                  text: "This record has been unpublished",
                  confirmButtonText: "close",
                  showCancelButton: false,
                  confirmButtonColor: "#008080",
                };
              }
              if (cassielAction === "delete") {
                redirect = true;
                swal["templateObj"] = {
                  title: "Delete!",
                  text: "This record has been deleted",
                  confirmButtonText: "close",
                  showCancelButton: false,
                  confirmButtonColor: "#008080",
                };
              }
            } else {
              this.displayAPIErrors(response, redirect);
            }

            //Run swal plugin here..
            let thisSwal = this.$swal(swal["templateObj"]);
            if (redirect) {
              thisSwal.then(() => {
                _self.$router.push({
                  name: "List Page",
                  params: {
                    widgetName: _self.widgetName,
                    entityName: _self.entityName,
                  },
                });
              });
            }
          })
          .catch((error) => {
            _self.$vs.loading.close();
            console.error(error);
            this.$swal({
              title: "Oops...",
              text: "Something went wrong!",
              confirmButtonText: "Ok",
              showCancelButton: false,
              confirmButtonColor: "#008080",
              customClass: {
                title: "error",
              },
            });
          });
      });
    },
  },
};
</script>
