<template>
  <form
    name="adminForm"
    id="adminForm"
    method="post"
    autocomplete="off"
    enctype="multipart/form-data"
  >
    <div class="row pageHeader-container">
      <div class="pageTitle-container">
        <div class="pageTitle">{{ widgetName }}</div>
        <ul class="pageBreadcrumbs">
          <li><a href="/">Dashboard </a></li>
          <li>
            <a href="/">&nbsp;/ {{ widgetName }}</a>
          </li>
          <li class="active">
            <a href="/">&nbsp;/ edit</a>
          </li>
        </ul>
      </div>
      <div class="shortUrl">
        Short URL to share: <span>{{ shortUrl }}</span>
      </div>
    </div>

    <vs-divider color="#C1C6E5" />

    <cassielhiddenfield :field="{ name: 'lastPageUrl', value: '' }" />
    <cassielhiddenfield
      :field="{ name: 'cassielAction', value: 'cassielid' }"
    />
    <cassielhiddenfield :field="{ name: 'cassielId', value: cassielid }" />
    <b-card no-body v-if="tabs">
      <b-tabs v-model="tabIndex" small card>
        <b-tab
          :title="tab.tabtitle"
          v-for="(tab, tabsIndex) in formConfiguration"
          v-bind:key="tabsIndex"
        >
          <div v-for="(field, fieldIndex) in tab.data" v-bind:key="fieldIndex">
            <cassielFieldsRender
              @addrow="addRow"
              @removerow="removeRow"
              @renamefield="renameField"
              :formConfiguration="formConfiguration"
              :field="field"
              :index="fieldIndex"
              :cassielid="cassielid"
              :widgetName="widgetName"
              :entityName="entityName"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <div
      v-for="(field, fieldIndex) in formConfiguration"
      v-bind:key="fieldIndex"
    >
      <cassielFieldsRender
        @addrow="addRow"
        @removerow="removeRow"
        @renamefield="renameField"
        :formConfiguration="formConfiguration"
        :field="field"
        :index="fieldIndex"
        :cassielid="cassielid"
        :widgetName="widgetName"
        :entityName="entityName"
      />
    </div>

    <div class="form-footer-container">
      <div style="display: flex; align-items: center">
        <button
          v-if="haveSaveAccess"
          type="submit"
          name="cassielAction"
          v-on:click.prevent="validate('save')"
          value="save"
          class="btn btn-warning btn-rounded btn-text-dark"
        >
          Save
        </button>
        <button
          v-if="haveSaveAccess"
          type="submit"
          name="cassielAction"
          v-on:click.prevent="validate('save-close')"
          value="save-close"
          class="btn btn-primary btn-rounded"
        >
          Save & Close
        </button>
        <button
          v-if="havePublishAccess"
          type="submit"
          name="cassielAction"
          v-on:click.prevent="validate('save-publish')"
          value="save-publish"
          class="btn btn-success btn-rounded"
        >
          Publish
        </button>
        <button
          v-if="haveUnPublishAccess"
          type="submit"
          name="cassielAction"
          v-on:click.prevent="validate('unpublish')"
          value="unpublish"
          class="btn btn-transparent btn-rounded"
        >
          Unpublish
        </button>
        <button
          v-if="haveDeleteAccess"
          type="button"
          class="btn btn-transparent btn-left-border btn-rounded"
          data-toggle="modal"
          data-target="#myModal"
          @click="showPopupMessage('delete')"
        >
          Delete
        </button>
      </div>

      <div
        style="display: flex; align-items: center; justify-content: flex-end"
      >
        <cassieldatetime
          class="col-md-8 col-lg-8 col-sm-6"
          v-if="scheduleEnabled"
          :field="{
            name: 'scheduleTime',
            type: 'datetime',
            value: '',
            required: false,
          }"
          name="scheduleTime"
          style="margin-right: 10px; margin-bottom: 0"
        />
        <button
          v-if="scheduleEnabled"
          type="submit"
          name="cassielAction"
          v-on:click.prevent="validate('schedule')"
          value="schedule"
          class="btn btn-primary btn-rounded"
        >
          Schedule
        </button>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span
                ><span class="sr-only">Close</span>
              </button>
              <h4 class="modal-title" id="myModalLabel">Delete Post</h4>
            </div>
            <div class="modal-body">
              Are you sure you want to "DELETE" this post?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                name="cassielAction"
                value="delete"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<style>
.shortUrl {
  font-size: 16px;
  font-weight: 400;
}
.shortUrl span {
  display: inline-block;
  background: #586bb2;
  color: #fff;
  font-size: 14px;
  padding: 4px 25px;
  font-weight: 300;
  border-radius: 5px;
  margin-left: 12px;
}

.card-header {
  background: #fff;
  border: none;
  height: 67px;
}

.nav-tabs .nav-link {
  background: transparent;
  color: #b3b8c9;
  font-size: 18px;
  padding: 20px 42px 15px;
  border: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #586bb2;
  background: transparent;
  border: 0;
  border-bottom: 4px solid #586bb2;
}
</style>
<script>
import cassielhiddenfield from "@/views/edit/Component/cassielhiddenfield";
import cassielFieldsRender from "@/views/edit/Component/cassielFieldsRender";
import { BCard, BTabs } from "bootstrap-vue";
import Cassieldatetime from "@/views/edit/Component/cassieldatetime";

export default {
  name: "cassielForm",
  mixins: [],
  props: {
    formConfiguration: {
      type: Array,
    },
    cassielid: {
      type: String,
    },
    widgetName: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    shortUrl: {
      type: String,
      required: true,
    },
    scheduleEnabled: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  components: {
    Cassieldatetime,
    cassielhiddenfield,
    cassielFieldsRender,
    BTabs,
    BCard,
  },

  created() {
    //todo: need to inject jquery in the headers before this to remove the "jquery is not defined error" !
    let previewLink = process.env.VUE_APP_PREVIEW_BASE_URL;
    let magicsuggestScript = document.createElement("script");
    magicsuggestScript.setAttribute(
      "src",
      previewLink + "/bundles/core/magicsuggest/js/magicsuggest.js"
    );
    document.head.appendChild(magicsuggestScript);

    let croppieScript = document.createElement("script");
    croppieScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.1/croppie.min.js"
    );
    document.head.appendChild(croppieScript);
  },

  computed: {
    tabs() {
      return (
        this.formConfiguration[1] &&
        this.formConfiguration[1].tabtitle &&
        this.formConfiguration[1].tabtitle.toLowerCase() === "seo"
      );
    },

    haveSaveAccess() {
      let context = {};
      context.role =
        "ROLE_" +
        this.widgetName.toUpperCase() +
        "_" +
        this.entityName.toUpperCase() +
        "_CREATE";
      return this.$acl.hasAccess(context);
    },

    havePublishAccess() {
      let context = {};
      context.role =
        "ROLE_" +
        this.widgetName.toUpperCase() +
        "_" +
        this.entityName.toUpperCase() +
        "_PUBLISH";
      return this.$acl.hasAccess(context);
    },

    haveUnPublishAccess() {
      if (!this.cassielid) {
        return false;
      }
      let context = {};
      context.role =
        "ROLE_" +
        this.widgetName.toUpperCase() +
        "_" +
        this.entityName.toUpperCase() +
        "_PUBLISH";
      return this.$acl.hasAccess(context);
    },

    haveDeleteAccess() {
      if (!this.cassielid) {
        return false;
      }
      let context = {};
      context.role =
        "ROLE_" +
        this.widgetName.toUpperCase() +
        "_" +
        this.entityName.toUpperCase() +
        "_DELETE";
      return this.$acl.hasAccess(context);
    },
  },

  methods: {
    showPopupMessage(cassielAction) {
      this.$swal({
        title: "Are you sure you want to 'DELETE' this post?",
        text: "Your will not be able to recover it!",
        confirmButtonText: "yes",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#008080",
        customClass: {
          title: "error",
          confirmButton: "confirm-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.validate(cassielAction);
        }
      });
    },

    addRow: function (e, fieldData, fieldIndex) {
      function fixName(entry, parentIndex) {
        if (!entry.name.includes("[]")) {
          if (parentIndex !== null && parentIndex !== undefined) {
            entry.name += parentIndex;
          }
          entry.name += "[]";
        }
      }

      e.preventDefault();
      let config = JSON.parse(JSON.stringify(fieldData.formConfiguration));
      config.forEach((oneEntry) => {
        if (oneEntry.name) {
          fixName(oneEntry, fieldIndex);
        }
      });

      fieldData.data.push(config);
    },

    removeRow: function (e, fieldData, index) {
      e.preventDefault();
      if (fieldData.data.length === 1 && index === 1) {
        fieldData.data = [];
      } else {
        fieldData.data.splice(index, 1);
      }
      this.renameField();
      // console.log(fieldData)
      // //loop in the questions holder
      // fieldData.data.forEach((oneDataNode) => {
      //   let counter = 0;
      //   //loop in the entries of the holder
      //   oneDataNode.forEach((oneChildDataNode) => {
      //     //if the entries has data array ==> answers of a questions
      //     if (oneChildDataNode.data && Array.isArray(oneChildDataNode.data)) {
      //       //loop in answers
      //       oneChildDataNode.data.forEach((oneFinalNode) => {
      //         oneFinalNode.forEach((oneFinalNodeField) => {
      //           //if the name of the answer has an index that doesn't match the question index, fix it
      //           if (!oneFinalNodeField.name.includes(`${counter}[]`)) {
      //             oneFinalNodeField.name = oneFinalNodeField.name.replace(
      //               /[0-9]+\[\]/,
      //               ""
      //             );
      //             //refix the index and add the brackets again
      //             oneFinalNodeField.name += counter;
      //             oneFinalNodeField.name += "[]";
      //           }
      //         });
      //       });
      //     }
      //    if(!oneChildDataNode.data){
      //      if (!oneChildDataNode.name.includes(`${counter}[]`)) {
      //        oneChildDataNode.name = oneChildDataNode.name.replace(
      //            /[0-9]+\[\]/,
      //            ""
      //        );
      //        //refix the index and add the brackets again
      //        oneChildDataNode.name += counter;
      //        oneChildDataNode.name += "[]";
      //      }
      //    }
      //   });
      //   counter++;
      // });
    },

    validate: function (cassielAction) {
      this.$emit("validate", cassielAction, this.tabs);
    },
    renameField: function () {
      this.formConfiguration.forEach((fieldData) => {
        if (fieldData.data) {
          let counter = 0;
          fieldData.data.forEach((oneDataNode) => {
            //loop in the entries of the holder
            oneDataNode.forEach((oneChildDataNode) => {
              //if the entries has data array ==> answers of a questions
              if (oneChildDataNode.data && Array.isArray(oneChildDataNode.data)) {
                //loop in answers
                oneChildDataNode.data.forEach((oneFinalNode) => {
                  oneFinalNode.forEach((oneFinalNodeField) => {
                    //if the name of the answer has an index that doesn't match the question index, fix it
                    if (!oneFinalNodeField.name.includes(`${counter}[]`)) {
                      oneFinalNodeField.name = oneFinalNodeField.name.replace(
                          /[0-9]+\[\]/,
                          ""
                      );
                      //refix the index and add the brackets again
                      oneFinalNodeField.name += counter;
                      oneFinalNodeField.name += "[]";
                    }
                  });
                });
              }
              if(!oneChildDataNode.data){
                if (!oneChildDataNode.name.includes(`${counter}[]`)) {
                  oneChildDataNode.name = oneChildDataNode.name.replace(
                      /[0-9]+\[\]/,
                      ""
                  );
                  //refix the index and add the brackets again
                  oneChildDataNode.name += counter;
                  oneChildDataNode.name += "[]";
                }
              }
            });
            counter++;
          });

        }
      });
    }
  },
};
</script>
